export enum Domain {
  TESC,
  SNXT,
}

export const getDomain = () => {
  // return Domain.SNXT; // uncomment to force Aileen
  switch (window.location.hostname) {
    case "parisinsights.tesc.io":
    case "parisinsights.dev.tesc.app":
    case "parisinsights.uat.tesc.app":
      return Domain.TESC;

    case "snxcloud.de":
    case "dev.snxcloud.de":
    case "uat.snxcloud.de":
      return Domain.SNXT;

    default:
      return Domain.TESC;
  }
};

/**
 * Returns the app name based on the current domain.
 * @returns {string} The app name corresponding to the domain.
 */
export const getAppName = (): string => {
  const domain = getDomain();

  switch (domain) {
    case Domain.TESC:
      return "PARIS";

    case Domain.SNXT:
      return "AI·leen";

    default:
      return "PARIS";
  }
};
