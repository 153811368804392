import React from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "../../../hooks/use-navigate";
import useSitesStore from "../../../hooks/use-sites";
import useCurrentSiteStore, {
  SitePage,
} from "../../../pages/sites/hooks/use-sites";
import useStyles from "./styles";
import { useTheme } from "react-jss";
import { Domain, getDomain } from "../../../utils/get-domain";

type MenuBarItemProps = {
  page: SitePage;
  route: string;
  translationId: string;
}

function MenuBarHeader() {
  const { siteId, isMultiSite } = useSitesStore((store) => ({
    siteId: store.siteId,
    isMultiSite: store.isMultiSite
  }))
  const { t } = useTranslation();
  const { page: currentParentPage } = useCurrentSiteStore();
  const { goToReplace } = useNavigate();
  const classes = useStyles();
  const theme: any = useTheme();
  const domain = getDomain();
  const isSNXT = domain === Domain.SNXT

  const onClick = (sitePage: SitePage, route: string) => {
    if (currentParentPage === sitePage) return;
    useCurrentSiteStore.getState().setPage(sitePage);
    if (sitePage === 0) {
      goToReplace(`/sites`); //go to all sites page if 'All Sites' clicked
      return
    }
    goToReplace(`/sites/${siteId}/${route}`);
  };

  const menuBarItems: MenuBarItemProps[] = [
    isMultiSite
      ? {
        page: SitePage.Home, route: "/sites", translationId: "All_Sites"
      }
      : null,
    !isSNXT
      ? {
      page: SitePage.Assets, route: "assets", translationId: "Assets" 
    }
    : null,
    {
      page: SitePage.Performance,
      route: "performance",
      translationId: "Performance",
    },
    { page: SitePage.Finance, route: "finance", translationId: "Finance" },
    !isSNXT
      ? {
      page: SitePage.Carbon, route: "carbon", translationId: "Carbon" 
    } : null,
    !isSNXT
      ? {
        page: SitePage.Tariff,
        route: "tariff",
        translationId: "Tariff", 
    } : null,
  ].filter((item): item is MenuBarItemProps => item !== null);

  return (
    <>
      <IonHeader
        data-testid="ionic-menu-bar-header"
        className="header-menu"
        mode="ios"
      >
        <IonToolbar
          color="red"
          style={{ backgroundColor: theme.primary.buttonBackgroundColor }}
        >
          <IonTitle color="dark">{t("My_Site")}</IonTitle>
        </IonToolbar>
        <IonToolbar
          color="red"
          style={{ backgroundColor: theme.primary.buttonBackgroundColor }}
        >
          <div
            style={{
              overflowX: "scroll",
              width: "100%",
            }}
          >
            <IonGrid fixed={true}>
              <IonRow>
                <IonButtons>
                  {menuBarItems.map((menuItem) => (
                    <IonCol key={`ion-col-${menuItem.route}`}>
                      <IonButton
                        key={`ion-button-${menuItem.route}`}
                        data-testid={`ion-button-${menuItem.route}`}
                        onClick={() => onClick(menuItem.page, menuItem.route)}
                      >
                        {t(menuItem.translationId)}
                      </IonButton>
                      <div
                        className={
                          "active-button-underline" +
                          `${
                            currentParentPage !== menuItem.page ? "-hidden" : ""
                          }`
                        }
                      ></div>
                    </IonCol>
                  ))}
                </IonButtons>
              </IonRow>
            </IonGrid>
          </div>
        </IonToolbar>
      </IonHeader>
    </>
  );
}

export default MenuBarHeader;
