import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Drawer,
  IconButton,
  List
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import i18next from 'i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  LineIcon,
  LogoutIcon,
  MenuIcon,
  MeterIcon,
  MoneyIcon,
  OverviewIcon,
  TreeIcon,
  DashboardIcon
} from '../icons'
import { Domain, getDomain } from '../../utils/get-domain'
import useSitesStore from '../../hooks/use-sites'
import Logo from './logo'
import useStyles from './styles'
import LanguageSwitcher from '../lang-switch'
import useAuthStore from '../../hooks/use-auth'

function NavBar() {
  const { siteId, isMultiSite } = useSitesStore((store) => ({
    siteId: store.siteId,
    isMultiSite: store.isMultiSite
  }))
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const domain = getDomain()
  const isSNXT = domain === Domain.SNXT

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const activeRoute = location.pathname
  const { logout } = useAuthStore()

  const logo = isSNXT
    ? '/images/Logo_SonneNext.webp'
    : '/images/PARIS INSIGHTS - STACKED.svg'

  const altText = isSNXT
    ? 'SonneNEXT Logo'
    : 'Joulen Logo'

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open)
  }

  const iconClasses = (route: string) => {
    const baseClass = classes.icon
    const activeClass = activeRoute === route ? `iconCurrent ${classes.iconCurrent} ` : ''
    const activeSNXTClass = activeRoute === route && isSNXT ? `${classes.iconCurrent_de} ` : ''
    return `${baseClass} ${activeClass} ${activeSNXTClass}`
  }

  type NavItem = {
    id: number;
    name: string;
    icon: JSX.Element;
    route: string;
    isLogout?: boolean;
  }

  const navItems: NavItem[] = [
    isMultiSite
      ? {
        id: 1,
        name: t('All_Sites'),
        icon: <DashboardIcon width={20} height={20} />,
        route: '/sites'
      }
      : null,
    !isSNXT
      ? {
        id: 2,
        name: t('Assets'),
        icon: <OverviewIcon width={20} height={20} />,
        route: `/sites/${siteId}/assets`
      }
      : null,
    {
      id: 3,
      name: t('Performance'),
      icon: <LineIcon width={20} height={20} />,
      route: `/sites/${siteId}/performance`
    },
    {
      id: 4,
      name: t('Finance'),
      icon: <MoneyIcon width={20} height={20} />,
      route: `/sites/${siteId}/finance`
    },
    !isSNXT
      ? {
        id: 5,
        name: t('Carbon'),
        icon: <TreeIcon width={20} height={20} />,
        route: `/sites/${siteId}/carbon`
      }
      : null,
    !isSNXT
      ? {
        id: 6,
        name: t('Tariff'),
        icon: <MeterIcon width={20} height={20} />,
        route: `/sites/${siteId}/tariff`
      }
      : null,
    {
      id: 7,
      name: t('Logout'),
      icon: <LogoutIcon width={20} height={20} />,
      route: '/logout',
      isLogout: true
    }
  ].filter((item): item is NavItem => item !== null)

  return (
    <Box>
      {/* Mobile Navigation */}
      <Box
        className={classes.navBar}
        sx={{
          display: { xs: 'flex', md: 'none' }
        }}
      >
        <button
          className={classes.logoButton}
          onClick={() => navigate('/site/overview')}
          onKeyDown={(e) => e.key === 'Enter' && navigate('/site/overview')}
          tabIndex={0}
          aria-label="Go to site overview"
          data-testid="responsive-logo"
          type="button"
        >
          <img
            src={logo}
            className={classes.logoMobile}
            alt={altText}
          />
        </button>

        <button
          className={classes.menu}
          onClick={() => toggleDrawer(true)}
          data-testid="menu-toggle"
          type="button"
        >
          <MenuIcon width={40} height={40} className={classes.hamburger} />
        </button>

        {/* Drawer for Mobile Navigation */}
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={() => toggleDrawer(false)}
          ModalProps={{ keepMounted: true }} // Improve performance on mobile
          className={classes.slideDialog}
        >
          {/* Close Button */}
          <IconButton
            className={classes.closeButtonStyles}
            onClick={() => toggleDrawer(false)}
          >
            <CloseIcon />
            <span className={classes.closeButtonTextStyles}>{t('Close')}</span>
          </IconButton>
          <Box
            sx={{
              flex: '1 1 auto',
              overflowY: 'auto',
              padding: '1.5rem 2rem',
              position: 'relative'
            }}
          >
            <h2 className={classes.title}>{t('Site_Navigation')}</h2>
            <LanguageSwitcher className={classes.switcher} />
            <Box
              sx={{
                width: 250,
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              {/* Navigation Items */}
              <List>
                {navItems.map((item) => (
                  <Box
                    key={item.id}
                    className={classes.navItem}
                    component="div"
                    onClick={() => (item.isLogout ? logout() : navigate(item.route))}
                    sx={{
                      opacity: activeRoute === item.route ? 1 : 0.5, // Full opacity for active item
                      '&:hover': {
                        opacity: 1, // Full opacity on hover for the entire navItem
                        '& .MuiBox-root': {
                          opacity: 1 // Ensure both the icon and name are fully visible on hover
                        }
                      },
                      '@media (max-width: 1000px)': {
                        opacity: 1
                      }
                    }}
                  >
                    <Box
                      className={iconClasses(item.route)}
                      sx={{
                        opacity: activeRoute === item.route ? 1 : 0.5
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Box
                      sx={{
                        opacity: activeRoute === item.route ? 1 : 0.5
                      }}
                    >
                      {item.name}
                    </Box>
                  </Box>
                ))}
              </List>
            </Box>
          </Box>
        </Drawer>
      </Box>
      {/* Desktop Navigation */}
      <Box
        className={`${classes.nav} ${
          i18next.language === 'en' ? classes.nav_en : classes.nav_de
        }`}
        sx={{
          display: { xs: 'none', md: 'block' },
          position: 'fixed',
          boxShadow: 3
        }}
        data-testid="desktop-nav"
      >
        <Logo />
        <List>
          {navItems.map((item) => (
            <Box
              key={item.id}
              className={classes.navItem}
              component="div"
              onClick={() => (item.isLogout ? logout() : navigate(item.route))}
              sx={{
                opacity: activeRoute === item.route ? 1 : 0.5, // Full opacity for active item
                '&:hover': {
                  opacity: 1, // Full opacity on hover for the entire navItem
                  '& .MuiBox-root': {
                    opacity: 1 // Ensure both the icon and name are fully visible on hover
                  }
                }
              }}
            >
              <Box className={iconClasses(item.route)}>
                {item.icon}
              </Box>
              <Box>
                {item.name}
              </Box>
            </Box>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default NavBar
