/* eslint-disable react/require-default-props */
import { CSSProperties, ReactNode } from 'react'
import useStyles from './styles'

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

function Box({ children, style = {} }: Props) {
  const classes = useStyles()

  return <div className={classes.box} style={style}>{children}</div>
}

export default Box
