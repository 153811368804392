import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme: any) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gap: 15,
    marginBottom: 15,
    width: '100%'
  },

  title: {
    fontSize: 13
  },

  subtitle: {
    color: theme.primary.textColor2,
    fontWeight: 500
  },

  tooltipMobile: {
    marginLeft: 10,
    float: 'left'
  },

  detailsRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10
  },

  label: {
    color: theme.primary.textColor2,
    display: 'flex',
    gap: 5
  },

  value: {
    color: theme.primary.textColor
  }
}))

export default useStyles
