import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  content_en: {
    marginLeft: 240,
  },

  content_de: {
    marginLeft: 240,
  },

  content: {
    padding: 30,

    "@media(max-width: 1200px)": {
      padding: 10,
    },

    "@media(max-width: 1000px)": {
      marginLeft: 0,
      padding: 5,
    },
  },

  noSites: {
    paddingTop: 20,
    color: theme.primary.textColor,
  },

  background: {
    backgroundColor: theme.primary.backgroundColor,
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: -10,
    top: 0,
    left: 0,
  },

  innerContent: {
    color: theme.primary.textColor,
  },
}));

export default useStyles;
