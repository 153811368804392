import { useEffect, useState } from 'react'
import {
  Navigate, Route, Routes, useParams
} from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { PageType, useNavigate } from '../../hooks/use-navigate'
import useSitesStore from '../../hooks/use-sites'
import SiteAssets from './assets'
import SiteCarbon from './carbon'
import SiteFinance from './finance'
import SitePerformance from './performance'
import SiteTariff from './tariff'
import client from '../../utils/client'
import { TZ_OFFSET } from '../../utils/constant'
import Loader from '../../core/loader'
import { Domain, getDomain } from '../../utils/get-domain'

function SitesPage() {
  const { siteId } = useParams()
  const { setPage } = useNavigate()
  const { t } = useTranslation()
  const isSingleSite = useSitesStore((store) => store.isSingleSite)
  const [loading, setLoading] = useState(true)
  const [siteData, setSiteData] = useState<any>({})
  const domain = getDomain()

  // Dynamically configure routes based on domain
  const routes = domain === Domain.SNXT
    ? [
      { id: 1, path: '/performance', element: <SitePerformance /> },
      { id: 2, path: '/finance', element: <SiteFinance site={siteData} /> }
    ]
    : [
      { id: 1, path: '/assets', element: <SiteAssets siteData={siteData} /> },
      { id: 2, path: '/performance', element: <SitePerformance /> },
      { id: 3, path: '/finance', element: <SiteFinance site={siteData} /> },
      { id: 4, path: '/carbon', element: <SiteCarbon /> },
      { id: 5, path: '/tariff', element: <SiteTariff site={siteData} /> },
      { id: 6, path: '*', element: <Navigate replace to={`/sites/${siteId}/assets`} /> }
    ]

  useEffect(() => {
    setPage(isSingleSite ? PageType.Dashboard : PageType.Sites)

    const load = async (): Promise<void> => { // Correct type: Promise<void>
      setLoading(true)
      try {
        const res = await client.put(
          `/site/getBySiteId/${siteId}/${TZ_OFFSET}`
        )
        setSiteData(res.data.response)
        setLoading(false)
        useSitesStore.setState({ siteId })
      } catch {
        enqueueSnackbar(t('Error_loading_sites'), {
          variant: 'error',
          persist: false
        })
      }
    }

    load() // no need to cast with 'void'
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <Routes>
        {routes.map(({ id, path, element }) => (
          <Route key={id} path={path} element={element} />
        ))}
      </Routes>
    </div>
  )
}

export default SitesPage
