import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  snextLogo: {
    cursor: 'pointer',
    width: 150,
    height: 'auto', // Ensure height is auto to maintain aspect ratio
    marginBottom: 15,
    display: 'flex', // Ensure the content is aligned
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden', // Prevent overflow if the image size is larger than the container
    border: 'none', // Remove button border
    background: 'none', // Remove button background
    padding: 0, // Remove button padding
    outline: 'none', // Remove outline on focus
    boxShadow: 'none' // Remove any box-shadow
  },

  joulenLogo: {
    cursor: 'pointer',
    marginLeft: -30,
    marginBottom: -20,
    width: '250px',
    marginTop: -40,
    display: 'flex', // Ensure the content is aligned
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden', // Prevent overflow if the image size is larger than the container
    border: 'none', // Remove button border
    background: 'none', // Remove button background
    padding: 0, // Remove button padding
    outline: 'none', // Remove outline on focus
    boxShadow: 'none' // Remove any box-shadow
  },

  // Style for images to make sure they don't overflow the container
  logoImage: {
    maxWidth: '100%', // Ensures the image does not exceed the button width
    height: 'auto', // Maintain aspect ratio
    objectFit: 'contain' // Ensures the image is scaled without distortion
  }
}))

export default useStyles
