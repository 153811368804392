import { createTheme } from '@mui/material'

const snxtTheme = {
  nav: {
    backgroundColor: 'white',
    hoverTextColour: '#F28705',
    defaultText: '#0E4C73',
    currentBackground: '#FFB861',
    currentTextColour: '#0E4C73',

    iconSelectedBackground: '#FFB861',
    iconSelectedText: '#0E4C73',
    iconSelectedBackgroundMobile: '#FFB861',
    iconSelectedTextMobile: '#0E4C73'
  },
  primary: {
    backgroundColor: '#ebf0f5',
    boxBackground: 'white',
    textColor: 'black',
    textColor2: '#56649a',
    buttonBackgroundColor: '#FFB861',
    buttonTextColor: '#20212e',

    borderRight: '#ebf0f5',
    primary1: '#177dbd',
    primary2: '#177dbd',
    primary3: '#177dbd',
    primary4: '#177dbd',
    primary5: '#FFB861',
    primary6: '#051926',

    tariffColor1: '#FFB861',
    tariffColor2: '#d4a46a',
    tariffColor3: '#7f7bed',
    tariffColor4: '#918fbd',
    tariffColor5: '#61ffb0',
    tariffColor6: '#68ad8b'
  },
  secondary: {
    main: '#F28705'
  },
  grey: {
    grey1: '#ebf0f5',
    grey2: '#c7c7c7',
    grey3: '#a4a4a4',
    grey5: '#56649a',
    grey6: '#848A9C',
    grey7: '#898989'
  },
  overlay: '#0000004d'
}

const snxtMuiTheme = createTheme({
  palette: {
    primary: {
      main: '#FFB861'
    },

    action: {
      disabledBackground: '#a4a4a4',
      disabled: '#0E4C73'
    }
  }
})

export { snxtMuiTheme, snxtTheme }
