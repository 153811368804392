import useStyles from "./styles";
import NavBar from "../core/nav-bar";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardPage from "./dashboard";
import ReportsPage from "./reports";
import SettingsPage from "./settings";
import AllSitesPage from "./all-sites";
import SitesPage from "./sites";
import ProductionPage from "./asset/production";
import BatteryPage from "./asset/battery";
import MeteringPointPage from "./asset/metering-point";
import ConsumptionPointPage from "./asset/consumption-point";
import NotificationsPage from "./notifications";
import useSitesStore from "../hooks/use-sites";
import { useEffect } from "react";
import useAuthStore from "../hooks/use-auth";
import ConfirmationDialog from "../core/confirmation-dialog";
import HelpPage from "./help";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { isNativeMobile } from "../utils/ionic";
import TopBar from "../core/top-bar";

const AuthenticatedRoutes = () => {
  const classes = useStyles();
  const loading = useSitesStore((store) => store.loading);
  const load = useSitesStore((store) => store.load);
  const isSingleSite = useSitesStore((store) => store.isSingleSite);
  const siteId = useSitesStore((store) => store.siteId);
  const isMultiSite = useSitesStore((store) => store.isMultiSite);
  const currentUser = useAuthStore((store) => store.currentUser);
  const { t } = useTranslation();

  useEffect(() => {
    load(currentUser);
  }, []);

  if (loading) return <div />;

  if (!isMultiSite && !isSingleSite) {
    return (
      <div>
        {!isNativeMobile() && <NavBar />}
        <div
          className={`${classes.content} ${classes.noSites} ${
            i18next.language === "en" ? classes.content_en : classes.content_de
          }`}
        >
          {t("This_account_not_set_up")}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.innerContent}>
      {!isNativeMobile() && <NavBar />}
      <div
        className={`${classes.content} ${
          i18next.language === "en" ? classes.content_en : classes.content_de
        }`}
      >
        {!isNativeMobile() && (
          <>
            <TopBar />
            <br />
          </>
        )}

        <Routes>
          {isSingleSite ? (
            <>
              <Route path={"/sites/:siteId/*"} element={<SitesPage />} />
            </>
          ) : (
            <>
              <Route path={"/sites"} element={<AllSitesPage />} />
              <Route path={"/sites/:siteId/*"} element={<SitesPage />} />
            </>
          )}
          <Route
            path={"/asset/production/:assetId"}
            element={<ProductionPage />}
          />
          <Route path={"/asset/battery/:assetId"} element={<BatteryPage />} />
          <Route
            path={"/asset/consumption/:assetId"}
            element={<ConsumptionPointPage />}
          />
          <Route
            path={"/asset/metering-point/:assetId"}
            element={<MeteringPointPage />}
          />
          <Route path={"/settings/*"} element={<SettingsPage />} />
          <Route path={"/notifications"} element={<NotificationsPage />} />
          <Route path={"/help"} element={<HelpPage />} />

          <Route
            path="*"
            element={
              <Navigate
                replace
                to={isSingleSite ? "/sites/" + siteId : "/sites"}
              />
            }
          />
        </Routes>
      </div>
      <ConfirmationDialog />
    </div>
  );
};

export default AuthenticatedRoutes;
