import { ReactNode } from 'react'
import { ThemeProvider as JssThemeProvider } from 'react-jss'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import { Domain, getDomain } from '../../utils/get-domain'
import { tescTheme, tescMuiTheme } from './tesc-theme'
import { snxtTheme, snxtMuiTheme } from './snext-theme'

interface Props {
  children: ReactNode;
}

function LocalThemeProvider({ children }: Props) {
  const domain = getDomain()
  const jssTheme = domain === Domain.TESC ? tescTheme : snxtTheme
  const muiTheme = domain === Domain.TESC ? tescMuiTheme : snxtMuiTheme

  return (
    <MuiThemeProvider theme={muiTheme}>
      <JssThemeProvider theme={jssTheme}>{children}</JssThemeProvider>
    </MuiThemeProvider>
  )
}
export default LocalThemeProvider
