import { useNavigate } from '../../../hooks/use-navigate'
import { Domain, getDomain } from '../../../utils/get-domain'
import useStyles from './styles'

function Logo() {
  const classes = useStyles()
  const domain = getDomain()
  const { goTo } = useNavigate()

  const handleClick = () => goTo('/site/overview')
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleClick()
    }
  }

  switch (domain) {
    case Domain.SNXT:
      return (
        <button
          type="button"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          className={classes.snextLogo}
          aria-label="Go to SonneNEXT overview"
        >
          <img
            data-testid="navMenuLogo"
            src="/images/Logo_SonneNext.webp"
            alt="SonneNEXT Logo"
            className={classes.logoImage}
          />
        </button>
      )

    default:
      return (
        <button
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          className={classes.joulenLogo}
          aria-label="Go to Joulen overview"
          type="button"
        >
          <img
            data-testid="navMenuLogo"
            src="/images/plus.svg"
            alt="Joulen Logo"
            className={classes.logoImage}
          />
        </button>
      )
  }
}

export default Logo
