import accounting from 'accounting'
import { useTranslation } from 'react-i18next'
import {
  IonLabel
} from '@ionic/react'
import { Stack } from '@mui/material'
import ToolTip from '../../../../mobile/tooltip'
import useStyles from './styles'
import { getCurrency } from '../../../../utils/constant'
import TooltipIcon from '../../../../core/icons/tooltip.svg'
import { isNativeMobile } from '../../../../utils/ionic'
import './styles.css'
import Box from '../../../../core/box'
import { getAppName } from '../../../../utils/get-domain'

interface Props {
  wouldHaveSpent: number;
  actualSpending: number;
  levelisedCost: number;
  totalRev: number;
  currency: string;
}

function FinanceTotals(props: Props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    wouldHaveSpent, actualSpending, levelisedCost, totalRev, currency
  } = props
  const savings = wouldHaveSpent - actualSpending
  const currencySymbol = getCurrency(currency)
  const appName = getAppName()

  return (
    isNativeMobile() ? (
      <>
        <div className={classes.label}>
          (
          {t('Estimated')}
          )
        </div>

        <div className={classes.detailsRow}>
          <div className={classes.label}>
            <IonLabel>
              {t('Actual_savings', { appName })}
            </IonLabel>
            <ToolTip
              tooltipText={t('Estimated_total_actual_savings', { appName })}
              icon={TooltipIcon}
              id="actual-saving-tooltipMobile"
            />
          </div>
          <div className={classes.value}>
            {accounting.formatMoney(savings, currencySymbol)}
          </div>
        </div>

        <div className={classes.detailsRow}>
          <div className={classes.label}>
            <IonLabel>
              {t('Levelised_Cost')}
            </IonLabel>
            <ToolTip
              tooltipText={t('Actual_amount_paying', { appName })}
              icon={TooltipIcon}
              id="levelised-cost-tooltipMobile"
            />
          </div>
          <div className={classes.value}>
            {accounting.formatMoney(levelisedCost * 1000, currencySymbol)}
          </div>
        </div>

        <div className={classes.detailsRow}>
          <div className={classes.label}>
            <IonLabel>
              {t('Export_Revenue')}
            </IonLabel>
            <ToolTip
              tooltipText={t('Estimated_total_revenue', { appName })}
              icon={TooltipIcon}
              id="export-revenue-tooltipMobile"
            />
          </div>
          <div className={classes.value}>
            {accounting.formatMoney(totalRev, currencySymbol)}
          </div>
        </div>

        <div className={classes.detailsRow}>
          <div className={classes.label}>
            <IonLabel>
              {t('Actual_spending', { appName })}
            </IonLabel>
            <ToolTip
              tooltipText={t('Estimated_actual_amount', { appName })}
              icon={TooltipIcon}
              id="actual-spending-tooltipMobile"
            />
          </div>
          <div className={classes.value}>
            {accounting.formatMoney(actualSpending, currencySymbol)}
          </div>
        </div>

        <div className={classes.detailsRow}>
          <div className={classes.label}>
            <IonLabel>
              {t('Would_have_spent', { appName })}
            </IonLabel>
            <ToolTip
              tooltipText={t('Estimated_total_amount', { appName })}
              icon={TooltipIcon}
              id="would-have-spent-tooltipMobile"
            />
          </div>
          <div className={classes.value}>
            {accounting.formatMoney(wouldHaveSpent, currencySymbol)}
          </div>
        </div>
      </>
    ) : (
      <div className={classes.container}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div className={classes.subtitle}>{t('Actual_savings', { appName })}</div>
          <h3>{accounting.formatMoney(savings, currencySymbol)}</h3>
          <Stack direction="row" spacing={1}>
            <div className={classes.title}>{t('Estimated_total_of')}</div>
            <ToolTip
              tooltipText={t('Estimated_total_actual_savings', { appName })}
              icon={TooltipIcon}
              id="actual-saving"
            />
          </Stack>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div className={classes.subtitle}>{t('Levelised_Cost')}</div>
          <h3>
            {accounting.formatMoney(levelisedCost * 1000, currencySymbol)}
          </h3>
          <Stack direction="row" spacing={1}>
            <div className={classes.title}>{t('Estimated_total_of')}</div>
            <ToolTip
              tooltipText={t('Actual_amount_paying', { appName })}
              icon={TooltipIcon}
              id="levelised-cost"
            />
          </Stack>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div className={classes.subtitle}>{t('Export_Revenue')}</div>
          <h3>{accounting.formatMoney(totalRev, currencySymbol)}</h3>
          <Stack direction="row" spacing={1}>
            <div className={classes.title}>{t('Estimated_total_of')}</div>
            <ToolTip
              tooltipText={t('Estimated_total_revenue', { appName })}
              icon={TooltipIcon}
              id="export-revenue"
            />
          </Stack>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div className={classes.subtitle}>{t('Actual_spending', { appName })}</div>
          <h3>{accounting.formatMoney(actualSpending, currencySymbol)}</h3>
          <Stack direction="row" spacing={1}>
            <div className={classes.title}>{t('Estimated_total_of')}</div>
            <ToolTip
              tooltipText={t('Estimated_actual_amount', { appName })}
              icon={TooltipIcon}
              id="actual-spending"
            />
          </Stack>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div className={classes.subtitle}>{t('Would_have_spent', { appName })}</div>
          <h3>{accounting.formatMoney(wouldHaveSpent, currencySymbol)}</h3>
          <Stack direction="row" spacing={1}>
            <div className={classes.title}>{t('Estimated_total_of')}</div>
            <ToolTip
              tooltipText={t('Estimated_total_amount', { appName })}
              icon={TooltipIcon}
              id="would-have-spent"
            />
          </Stack>
        </Box>
      </div>
    )
  )
}

export default FinanceTotals
