import { createUseStyles } from 'react-jss'
import { Domain, getDomain } from '../../utils/get-domain'

const domain = getDomain()
const isNotSNXT = domain !== Domain.SNXT

const useStyles = createUseStyles((theme: any) => ({
  nav_de: {
    width: '225px'
  },

  nav_en: {
    width: '175px'
  },

  nav: {
    backgroundColor: theme.nav.backgroundColor,
    margin: 0,
    paddingTop: 30,
    paddingLeft: 20,
    position: 'fixed',
    height: '100%',
    overflow: 'auto',
    borderRight: `2px solid ${theme.nav.borderRight}`,
    width: 225
  },

  logoButton: {
    all: 'unset',
    display: 'inline-block',
    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    outline: 'none'
  },

  logo: {
    cursor: 'pointer',
    width: 150,
    marginBottom: 15
  },

  logoMobile: {
    padding: 0,
    paddingBottom: 0,
    cursor: 'pointer',
    borderRadius: 7,
    width: 100,
    height: 'auto'
  },

  navItem: {
    all: 'unset',
    display: 'flex',
    padding: 10,
    cursor: 'pointer',
    color: theme.primary.buttonTextColor,
    alignItems: 'center',

    '&:hover': {
      opacity: 1
    },

    '@media (max-width: 1000px)': {
      color: theme.primary.textColor
    }
  },

  navItemCurrent: {
    opacity: 1
  },

  icon: {
    marginRight: 7,
    height: 35,
    width: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  switcher: {
    margin: 15
  },

  title: {
    color: theme.primary.textColor
  },

  iconCurrent: {
    marginRight: 7,
    color: theme.nav.iconSelectedBackgroundMobile,
    backgroundColor: theme.nav.iconSelectedTextMobile,
    borderRadius: 100,
    '@media (max-width: 1000px)': {
      backgroundColor: theme.nav.iconSelectedBackgroundMobile,
      color: theme.nav.iconSelectedTextMobile
    }
  },

  iconCurrent_de: {
    color: theme.nav.iconSelectedTextMobile,
    backgroundColor: theme.nav.iconSelectedBackgroundMobile
  },

  menu: {
    all: 'unset',
    cursor: 'pointer',
    marginTop: 3
  },

  hamburger: {
    color: theme.primary.primary4
  },

  navBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5
  },

  slideDialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)'
    },
    '& .MuiDrawer-paper': {
      width: '80%',
      maxWidth: 'none',
      backgroundColor: isNotSNXT ? theme.primary.boxBackground : 'white'
    }
  },

  closeButtonStyles: {
    position: 'absolute',
    alignSelf: 'flex-end',
    top: 16,
    right: 16,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 9999,
    color: `${theme.primary.primary5} !important`
  },

  closeButtonTextStyles: {
    marginLeft: 8,
    display: 'inline',
    'font-size': 16
  }

}))

export default useStyles
