import { useMemo } from "react";
import LineChart from "../../../../core/chart/line";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../../../../utils/constant";
import { isNativeMobile } from "../../../../utils/ionic";
import { getChartOptions, getMinUnitsForDateRange } from "../../../../utils/chart";
import { DateRange } from "../../../../utils/types";
import { getAppName } from '../../../../utils/get-domain'

interface Props {
  labels: any[];
  saved: {x: number, y: number}[];
  spending: {x: number, y: number}[];
  wouldHaveSpent: {x: number, y: number}[];
  loading: boolean;
  exportedRev: {x: number, y: number}[];
  currency: string;
  dateRange: DateRange
}

const LineGraph = (props: Props) => {
  const { labels, saved, spending, wouldHaveSpent, exportedRev, loading, currency, dateRange } =
    props;
  const currencySymbol = getCurrency(currency);
  const { t } = useTranslation();
  const appName = getAppName()
  const options = useMemo(() => {
    const { startDateTime, endDateTime } = dateRange;
    const minUnit = getMinUnitsForDateRange(startDateTime, endDateTime);
    
    const lineOverrides = !isNativeMobile() ? { 
      elements: {
        line: {
          fill: false
        }
      }
    } : {};

    const scalesOverrides = {
      scales: {
        x: {
          type: 'time',
          time: {
              minUnit,
              tooltipFormat: 'DD/MM HH:mm'
          },
        },
        y: {
          ticks: {
            callback: (value: any) => {
              return currencySymbol + value;
            },
          },
        },
        y1: {
          display: false,
        }
      }
    };

    const pluginsOverrides = {
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              const { label } = tooltipItem.dataset;
              const { formattedValue } = tooltipItem;

              return label + ": " + currencySymbol + formattedValue;
            }
          }
        }
      }
    };

    return getChartOptions({
      ...lineOverrides,
      ...scalesOverrides,
      ...pluginsOverrides
    });
  }, [dateRange]);

  const data = useMemo(() => {
    return {
      datasets: [
        {
          label: t("Amount_Saved_Finance", { appName }),
          data: saved,
          borderColor: "rgba(50,205,50,1.0)",
          backgroundColor: "rgba(50,205,50,0.2)",
          borderWidth: 1,
        },
        {
          label: t("Exported_Revenue", { appName }),
          data: exportedRev,
          borderColor: "rgba(252,186,3,1.0)",
          backgroundColor: "rgba(252,186,3,0.2)",
          borderWidth: 1,
        },
        {
          label: t("Amount_Spent", { appName }),
          data: spending,
          borderColor: "rgba(192,57,43,1.0)",
          backgroundColor: "rgba(192,57,43,0.2)",
          borderWidth: 1,
        },
        {
          label: t("Estimated_Would_Have_Spent", { appName }),
          data: wouldHaveSpent,
          borderColor: "rgba(0,114,198,1.0)",
          backgroundColor: "rgba(0,114,198,0.2)",
          borderWidth: 1,
        }
      ],
    };
  }, [spending, saved, wouldHaveSpent, exportedRev]);

  return (
    <div style={{ height: "500px" }}>
      <LineChart
        loading={loading}
        data={data}
        canZoom={!isNativeMobile()}
        canExport={!isNativeMobile()}
        exportName="finance"
        options={options}
      />
    </div>
  );
};

export default LineGraph;
